import { ReactComponent as Linkedin } from './../../assets/linkedin-round-svgrepo-com.svg'
import { ReactComponent as Instagram } from './../../assets/instagram-svgrepo-com.svg'
import { ReactComponent as Email } from './../../assets/email-svgrepo-com.svg'
import { INSTAGRAM, LINKEDIN, MAIL } from './../../constants/links';


export default function FooterComponent() {
    return (
        // <div className="h-64 w-full bg-gradient-to-b from-transparent to-black via-black">
        //     <div className="container mx-auto h-full px-4">
        //         <div className="h-full flex flex-col justify-end text-[0.8rem] lg:text-[1rem]">
                
        //             <div className="">
        //                 <div className='  text-gray-200'>
        //                     <div className='py-6 font-bold'>CONTACT</div>
        //                     <div className='flex flex-col md:flex-row justify-between'>
        //                         <div>
        //                             <div className='hidden md:inline'>Send me an email I can't refuse</div>
        //                             <div className='flex flex-row py md:py-3' onClick={(e)=>{
        //                                 window.location.href = "mailto:" + MAIL ;
        //                                 e.preventDefault();
        //                             }}><div className='pr-6 cursor-pointer flex justify-center'><Email className=' w-7/12 md:w-10/12 md:hover:w-11/12 transition-all'/> </div> <div className='flex items-center'>shankh411@gmail.com</div></div>
        //                         </div>
                                
        //                         <div>
        //                             <div  className='hidden md:inline'>Slide into my DMs</div>
        //                             <div className='flex flex-row py md:py-3' onClick={(e)=>{
        //                                 window.location.href = INSTAGRAM
        //                             e.preventDefault()}}>
        //                                 <div className='pr-6 cursor-pointer flex justify-center'><Instagram className='w-7/12 md:w-10/12 md:hover:w-11/12 transition-all'/></div> <div className='flex items-center'>@shankhchatterjee</div></div>
        //                         </div>

        //                         <div>
        //                             <div  className='hidden md:inline'>Let's talk beyond that connect button</div>
        //                             <div className='flex flex-row py md:py-3' onClick={(e)=>{
        //                                 window.location.href = LINKEDIN
        //                             e.preventDefault()}}><div className='pr-6 cursor-pointer flex justify-center'><Linkedin className='w-8 h-8 md:w-9 md:hover:w-9 md:hover:h-9 transition-all'/></div> <div className='flex items-center'>@shankhchatterjee</div></div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
                    
        //         <div className="flex justify-center text-white my-5 " >All rights reserved © Shankh Chatterjee 2022</div>
        //         </div>
                
        //     </div>


        // </div>
        <div className="flex justify-center text-backwhite my-5 text-[0.6rem]" >All rights reserved © Shankh Chatterjee 2024</div>
    )
}