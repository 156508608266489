export const tickerHero = [
    {   
        content: "SCROLL TO EXPLORE",
        subtitle: ""
    },
    
    {   
        content: "SCROLL TO EXPLORE",
        subtitle: ""
    },
]

export const tickerContent = [
    {   
        content: "HOVER TO SEE MORE",
        subtitle: ""
    },
    {
        content: "CLICK TO EXPLORE",
        subtitle: ""
    },

]