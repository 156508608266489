import React from 'react'

import { INSTAGRAM, LINKEDIN, MAIL } from '../../constants/links';
import  { useRef, useState, useEffect, CSSProperties, useContext } from 'react'


import { ReactComponent as Linkedin } from './../../assets/linkedin-round-svgrepo-com.svg'
import { ReactComponent as Instagram } from './../../assets/instagram-svgrepo-com.svg'
import { ReactComponent as Email } from './../../assets/email-svgrepo-com.svg'

import ContactUs from '../../components/email';
import {
    motion
  } from "framer-motion";
import { useNavigate } from 'react-router-dom';
import FooterComponent from '../../components/footer';
import NavbarComponent from '../../components/navbar';

type contactForm = {
    fullName?:string,
    email?:string,
    subject?:string,
    message?:string,
}

export default function ContactPage() {

    const[mouseVariant, setMouseVariant] = useState("default")
    const navigate = useNavigate()

    const[mousePosition, setMousePosition] = useState({
        x : 0,
        y : 0,
    })

    const variants = {
        default : {
            x : mousePosition.x -16,
            y : mousePosition.y -16,
            
        },

        text : {
            x : mousePosition.x - 16 ,
            y : mousePosition.y - 16,
            scale: 8,
        },

        floating : {
            x : mousePosition.x - 64,
            y : mousePosition.y - 32,
        }
    }

    useEffect(() => {
        const mouseMove = (e: MouseEvent) => {
            setMousePosition({
                x: e.clientX,
                y: e.clientY,
            })
        }
        window.addEventListener('mousemove', mouseMove)

        return () => {
            window.removeEventListener('mousemove', mouseMove)
        }

    }, []);

  return (
    <>
        <div className="bg-gradient-to-b from-black from-30% to-[#b3b3b3] bg-cover bg-center bg-fixed min-h-screen ">
            <motion.div variants={variants} className='hidden lg:block cursor fixed top-0 left-0 z-50' animate={mouseVariant} transition={{ type: "spring", duration: 0.0 }} >
            </motion.div>
            <div className='flex flex-col justify-between'>
            <NavbarComponent isVisible={true}></NavbarComponent>
                <div className='container mx-auto min-w-full md:min-w-0 md:max-w-screen-xl scrollbar text-backwhite'>
                <section className=" flex flex-col justify-center max-h-screen-2xl relative text-[0.8rem] mx-10">
                     <div className='uppercase font-bold mt-10'>
                        Contact
                    </div>   
                           
                         <ContactUs></ContactUs>                   
                                        

                </section>
                </div>
            <FooterComponent/>                                     
            </div>
        </div>
    
    </>
  )
}
