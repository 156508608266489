import PAGES from '../pages'
import * as PATHS from './paths'

export const LANDINGPAGE = {
    path: '/',
    component: PAGES.LandingPage,
    exact: true,
    meta: {
        protected: false,
        restricted: false
    }
    
}

export const PROJECTPAGE = {
    path: '/projects',
    component: PAGES.ProjectPage,
    exact: true,
    meta: {
        protected: false,
        restricted: false
    }
    
}

export const ABOUTPAGE = {
    path: '/about',
    component: PAGES.AboutPage,
    exact: true,
    meta: {
        protected: false,
        restricted: false
    }
    
}

export const CONTACTPAGE = {
    path: '/contact',
    component: PAGES.ContactPage,
    exact: true,
    meta: {
        protected: false,
        restricted: false
    }
    
}

export const NOTFOUND = {
    path: PATHS.NOTFOUND,
    component: PAGES.NotFound,
    exact: false,
    meta: {
        protected: false,
        restricted: false
    }
}




const ROUTES = [LANDINGPAGE,  NOTFOUND, PROJECTPAGE, ABOUTPAGE, CONTACTPAGE]
export default ROUTES