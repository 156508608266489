import  { useRef, useState, useEffect, CSSProperties, useContext } from 'react'
import NavbarComponent from "../../components/navbar";
import { tickerContent, tickerHero } from './ticker';


import { ReactComponent as Cinstagram } from './../../assets/icons8-instagram-48.svg'
import { ReactComponent as Cwhatsapp } from './../../assets/whatsapp-svgrepo-com.svg';
import { ReactComponent as Cgmail } from './../../assets/gmail.svg';
import { ReactComponent as Clinkedin } from './../../assets/linkedin.svg';
import { ReactComponent as Ctelegram} from './../../assets/telegram-svgrepo-com.svg';
import { ReactComponent as Cinternet} from './../../assets/internet.svg';
import { ReactComponent as Cvideo } from './../../assets/video-call.svg'
import { ReactComponent as Aindia} from './../../assets/india.svg';
import { ReactComponent as Agermany} from './../../assets/germany.svg';
import { ReactComponent as Ainformation} from './../../assets/information.svg';
import { ReactComponent as Aweb} from './../../assets/web-design.svg';
import { ReactComponent as Aux} from './../../assets/ux-design.svg';
import { ReactComponent as Agraphics} from './../../assets/graphic-designer.svg';
import { ReactComponent as Procket} from './../../assets/rocket.svg';
import { ReactComponent as Psales} from './../../assets/Salesforce.svg';
import { ReactComponent as Padobe} from './../../assets/Adobe_Creative_Cloud_rainbow_icon.svg';
import { ReactComponent as Pmaggi} from './../../assets/Maggi_logo.svg';
import { ReactComponent as Pfigma} from './../../assets/figma.svg';
import { ReactComponent as Ppublicis} from './../../assets/publicis.svg';
import { ReactComponent as Pnescafe} from './../../assets/Nescafe_logo_2014.svg';
import { ReactComponent as Players} from './../../assets/layers.svg';
import { useInView as useIsVisible } from 'react-intersection-observer';

import { useNavigate } from 'react-router-dom';

import SKEDImage from './../../assets/SKED-Desktop-PDF.png'

import {
    motion,
    useScroll,
    useTransform,
    MotionValue,
    useInView,
    AnimatePresence,
  } from "framer-motion";

import { useAnimate } from "framer-motion"
import TickerComponent from '../../components/ticker';
import { SectionContext, useSectionContextValues } from '../../utils/SectionContext';
import Section from '../../components/Section';
import ScrollBar from '../../components/scrollbar';


export default function LandingPage(){
    let SCALE = (window.innerWidth > 640)  ? 1 : 0

    const conte = useRef(null);
    const aboutRef = useRef<HTMLDivElement>(null)
    const mainContainterRef = useRef<HTMLDivElement>(null)
    const [workNumber, setWorkNumber] = useState(1)
    const [isHover, setIsHover] = useState(false)
    const [isProject, setProject] = useState(false)
    const [isContact, setContact] = useState(false)
    const [isAbout, setAbout] = useState(false)
    const [scopeFull, animateFull] = useAnimate()
    const [scopeC, animateC] = useAnimate()
    const [scopeA, animateA] = useAnimate()
    const [scopeP, animateP] = useAnimate()
    const [firstRef, isfirstTicker, Entry] = useIsVisible();
    const [secondtRef, issecondtTicker, sEntry] = useIsVisible();
    const [showNavbarOpts, setShowNavbarOpts] = useState(false)
    const navigate = useNavigate()
    // mouse pointer
    const[mousePosition, setMousePosition] = useState({
        x : 0,
        y : 0,
    })
    const[mouseVariant, setMouseVariant] = useState("default")
    const isLast = false;
    const isFirst = false;
    const  sectionContext = useContext(SectionContext);
    const activeSection = sectionContext?.activeSection
    const isInViewC = useInView(scopeC)
    const isInViewA = useInView(scopeA)
    const isInViewP = useInView(scopeP)

    const variants = {
        default : {
            x : mousePosition.x -16,
            y : mousePosition.y -16,
            
        },

        text : {
            x : mousePosition.x - 16 ,
            y : mousePosition.y - 16,
            scale: 8,
        },

        floating : {
            x : mousePosition.x - 64,
            y : mousePosition.y - 32,
        }
    }

    const textEnter = (x: string) => {
        setIsHover(true)
        setMouseVariant("text")
        if (x == 'project'){
            setProject(true)
        }
        if (x == 'about'){
            setAbout(true)
        }
        if (x == 'contact'){
            setContact(true)
        }

    }
    const textLeave = () => {
        setIsHover(false)
        setMouseVariant("default")
        setProject(false)
        setContact(false)
        setAbout(false)
    }

    const textStyle = () => {
        return 'snap-center text-[2.5rem] flex justify-center items-center font-design h-full w-full text-center flex-shrink-0 md:shrink'
    }

    const hideText = (x: boolean) => {
        if (x){
            return 'md:text-backwhite'
        }
        else{
            return ''
        }
    }

    const showText = (x: boolean) => {
        if (x){
            return 'hidden md:block fixed top-0 left-0 z-50 pointer-events-none text-[2.5rem] font-design text-backwhite'
        }
        else{
            return 'hidden'
        }
    }

    const {scrollXProgress} = useScroll({
        container: conte,
        offset: isFirst ? ["start start", "end center"] : isLast ? ["start center", "end end"] :["start center", "end center"]
    });

    

    useEffect(() => {
        const mouseMove = (e: MouseEvent) => {
            setMousePosition({
                x: e.clientX,
                y: e.clientY,
            })
        }
        window.addEventListener('mousemove', mouseMove)

        return () => {
            window.removeEventListener('mousemove', mouseMove)
        }

    }, []);

    useEffect(() => {

        const animateIconC = async (id: string, y_val: number, time: number) => {
            if(isInViewC){
                await animateC(id, {y: y_val, opacity:0.2}, {duration:  time})
                await animateC(id, {y: [y_val, (y_val+3) ], opacity:0.2}, {duration: 1.5, repeatType: "reverse", repeat: Infinity})
            }
        }

        // contacts
        animateIconC('#instagram', -140, 1.7)
        animateIconC('#whatsapp', -120, 1.4)
        animateIconC('#linkedin', -145, 1.5)
        animateIconC('#gmail', -70, 1.6)
        animateIconC('#telegram', -110, 1.7)
        animateIconC('#internet', -90, 1.7)
        animateIconC('#video', -190, 1.6)



    }, [isInViewC]);

    useEffect(() => {
        const animateIconA = async (id: string, y_val: number, time: number) => {
            if(isInViewA){
                await animateA(id, {y: y_val, opacity:1}, {duration:  time})
                await animateA(id, {y: [y_val, (y_val+3) ], opacity:1}, {duration: 1.5, repeatType: "reverse", repeat: Infinity})
            }
        }
        
        // about
        animateIconA('#germany', -70, 1.7)
        animateIconA('#india', -120, 1.4)
        animateIconA('#information', -145, 1.5)
        animateIconA('#ux', -190, 1.6)
        animateIconA('#web', -110, 1.7)
        animateIconA('#graphics', -90, 1.7)

    }, [isInViewA]);

    useEffect(() => {

        const animateIconP = async (id: string, y_val: number, time: number) => {
            if(isInViewP){
                await animateP(id, {y: y_val, opacity:1}, {duration:  time})
                await animateP(id, {y: [y_val, (y_val+3) ], opacity:1}, {duration: 1.5, repeatType: "reverse", repeat: Infinity})
            }
        }
        
        // project
        animateIconP('#adobe', -140, 1.7)
        animateIconP('#rocket', -200, 1.7)
        animateIconP('#figma', -100, 1.4)
        animateIconP('#layers', -170, 1.3)
        animateIconP('#publicis', -90, 1.6)
        animateIconP('#sales', -190, 1.7)
        animateIconP('#maggi', -100, 1.5)
        animateIconP('#nescafe', -90, 1.5)


    }, [isInViewP]);

    useEffect(() => {

        if(isProject){
            const animateIconFP = async (id: string, y_val: number, time: number) => {
                    await animateFull(id, {y: y_val, opacity:1}, {duration:  time})
                    await animateFull(id, {y: [y_val, (y_val+3) ], opacity:1}, {duration: 1.5, repeatType: "reverse", repeat: Infinity})
            }
            // project
            animateIconFP('#adobe', -150, 1.7)
            animateIconFP('#rocket', -160, 1.7)
            animateIconFP('#figma', -160, 1.4)
            animateIconFP('#layers', -100, 1.3)
            animateIconFP('#publicis', -100, 1.6)
            animateIconFP('#sales', -150, 1.7)
            animateIconFP('#maggi', -110, 1.5)
            animateIconFP('#nescafe', -90, 1.5)
        }

    }, [isProject]);

    useEffect(() => {

        const animateIconC = async (id: string, y_val: number, time: number) => {
            if(isContact){
                await animateFull(id, {y: y_val, opacity:0.2}, {duration:  time})
                await animateFull(id, {y: [y_val, (y_val+3) ], opacity:0.2}, {duration: 1.5, repeatType: "reverse", repeat: Infinity})
            }
        }

        // contacts
        animateIconC('#instagram', -140, 1.7)
        animateIconC('#whatsapp', -120, 1.4)
        animateIconC('#linkedin', -145, 1.5)
        animateIconC('#gmail', -70, 1.6)
        animateIconC('#telegram', -110, 1.7)
        animateIconC('#internet', -90, 1.7)
        animateIconC('#video', -170, 1.6)


    }, [isContact]);

    useEffect(() => {
        const animateIconA = async (id: string, y_val: number, time: number) => {
            if(isAbout){
                await animateFull(id, {y: y_val, opacity:1}, {duration:  time})
                await animateFull(id, {y: [y_val, (y_val+3) ], opacity:1}, {duration: 1.5, repeatType: "reverse", repeat: Infinity})
            }
        }
        
        // about
        animateIconA('#germany', -70, 1.7)
        animateIconA('#india', -120, 1.4)
        animateIconA('#information', -145, 1.5)
        animateIconA('#ux', -190, 1.6)
        animateIconA('#web', -150, 1.7)
        animateIconA('#graphics', -90, 1.7)

    }, [isAbout]);

    return (
        <>
        <div className="bg-backwhite bg-cover bg-center bg-fixed ">
        <motion.div variants={variants} className='hidden lg:block cursor fixed top-0 left-0 z-50' animate={mouseVariant} transition={{ type: "spring", duration: 0.0 }} >
        </motion.div>
        <motion.div variants={variants} className={showText(isProject)} animate={'floating'} transition={{type: 'spring', mass: 0.1, stiffness: 150}}>Projects</motion.div>
        <motion.div variants={variants} className={showText(isAbout)} animate={'floating'} transition={{type: 'spring', mass:0.1, stiffness: 150}}>About</motion.div>
        <motion.div variants={variants} className={showText(isContact)} animate={'floating'} transition={{type: 'spring', mass:0.1, stiffness:150}}>Contact</motion.div>
        
            <div className='flex flex-col justify-between'>
                <NavbarComponent isVisible={false}/>
                
                <div ref={mainContainterRef} className=" snap-y snap-mandatory overflow-y-scroll h-[90vh] container mx-auto min-w-full md:min-w-0 md:max-w-screen-xl scrollbar" >

                        <div className="snap-start flex relative h-[90vh]">
                                <div className="absolute top-[30%] md:top-[20%] w-full md:w-5/6 text-hero">
                                    <div><h1 ref={firstRef} className=" relative text-[3.5rem] md:text-[9rem] font-normal leading-[3rem] md:leading-[8rem] ">Experience <span className='absolute right-20 md:right-0 -top-8 md:top-20 text-[1rem] md:text-[1.7rem]'>PORTFOLIO</span></h1></div>
                                    <h1 className="text-[3.5rem] md:text-[9rem] text-right font-design font-thin italic leading-[3rem] md:leading-[8rem]">designer </h1>
                                </div>
                                
                        </div>

                        <div ref={scopeFull} className="snap-start flex flex-col h-[90vh] justify-center items-center relative">
                                <div ref={conte} className=" flex scrollbar text-hero h-3/4 md:h-full w-full px-4 overflow-x-auto snap-x snap-mandatory -translate-y-12 relative">
                                    <Section section={1} isFirst={isFirst} scrollval={scrollXProgress} className={textStyle()}>
                                        <div className="pt-10  relative" onMouseEnter={() => textEnter('project')} onMouseLeave={textLeave} onClick={() => navigate('/projects')} ref={scopeP}>
                                            <div ref={secondtRef} className={hideText(isProject)}>Projects</div>
                                            {(!SCALE) && <div id="adobe" className='absolute -bottom-10 right-16 opacity-0 w-5'><Padobe/></div>}
                                            {(!SCALE) ? <div id="figma" className='absolute -bottom-32 -right-10 opacity-0 w-5'><Pfigma/></div>:""}
                                            {(!SCALE) ? <div id="layers" className='absolute -bottom-96 -left-10 opacity-0 w-5'><Players/></div>:""}
                                            {(!SCALE) ? <div id="maggi" className='absolute -bottom-24 -left-20 opacity-0 w-5'><Pmaggi/></div>:""}
                                            {(!SCALE) ? <div id="nescafe" className='absolute -bottom-64 -right-1 opacity-0 w-5'><Pnescafe/></div>:""}
                                            {(!SCALE) ? <div id="publicis" className='absolute -bottom-48 -left-5 opacity-0 w-5'><Ppublicis/></div>:""}
                                            {(!SCALE) ? <div id="rocket" className='absolute bottom-5 -left-20 opacity-0 w-5'><Procket/></div>:""}
                                            {(!SCALE) ? <div id="sales" className='absolute bottom-4 -right-1 opacity-0 w-5'><Psales/></div>:""}
                                        </div>
                                    </Section>
                                    <Section  section={2} scrollval={scrollXProgress} className={textStyle()}>
                                        <div className="pt-10 relative" onMouseEnter={() => textEnter('about')} onMouseLeave={textLeave} onClick={() =>  navigate('/about')} ref={scopeA}>
                                            <div className={hideText(isAbout)}>About</div>
                                            {(!SCALE) && <div id="germany" className='absolute -bottom-64 -right-1 opacity-0 w-5'><Agermany/></div>}
                                            {(!SCALE) && <div id="india" className='absolute -bottom-48 -left-20  opacity-0 w-5'><Aindia/></div>}
                                            {(!SCALE) && <div id="graphics" className='absolute -bottom-32 -right-1 opacity-0 w-5'><Agraphics/></div>}
                                            {(!SCALE) && <div id="information" className='absolute -bottom-10  right-6 opacity-0 w-5'><Ainformation/></div>}
                                            {(!SCALE) && <div id="ux" className='absolute bottom-16 -right-8 opacity-0 w-5'><Aux/></div>}
                                            {(!SCALE) && <div id="web" className=' absolute bottom-16 -left-16 opacity-0  w-10 h-10'><Aweb/></div>}
                                        </div>
                                    </Section>
                                    <Section  section={3} scrollval={scrollXProgress} className={textStyle()}>
                                        <div className="pt-10 relative" onMouseEnter={() => textEnter('contact')} onMouseLeave={textLeave} onClick={() =>  navigate('/contact')} ref={scopeC}>
                                            <div className={hideText(isContact)}>Contact</div>
                                            {(!SCALE) &&<div id="instagram" className='absolute -bottom-32 -left-20 opacity-0'><Cinstagram></Cinstagram></div>}
                                            {(!SCALE) &&<div id="gmail" className='absolute -bottom-64 -right-12 opacity-0 w-12 h-12'><Cgmail ></Cgmail></div>}
                                            {(!SCALE) &&<div id="telegram" className='absolute -bottom-60 -left-12 opacity-0  w-10 h-10'><Ctelegram ></Ctelegram></div>}
                                            {(!SCALE) &&<div id="whatsapp" className='absolute bottom-16 -left-8 opacity-0  w-10 h-10'><Cwhatsapp/></div>}
                                            {(!SCALE) &&<div id="linkedin" className='absolute bottom-20 -right-1  opacity-0 w-5'><Clinkedin/></div>}
                                            {(!SCALE) &&<div id="internet" className='absolute -bottom-32 -right-1 opacity-0 w-5'><Cinternet/></div>}
                                            {(!SCALE) &&<div id="video" className='absolute -bottom-32 -right-1 opacity-0 w-5'><Cvideo/></div>}
                                        </div>
                                    </Section>     

                                    <AnimatePresence>{SCALE && isProject && <motion.div exit={{opacity: 0}} id="adobe" className='absolute top-[45%] left-1/3 opacity-0 w-8'><Padobe/></motion.div>}</AnimatePresence>
                                    <AnimatePresence>{SCALE && isProject && <motion.div exit={{opacity: 0}} id="figma" className='absolute bottom-60 right-20 opacity-0 w-8'><Pfigma/></motion.div>}</AnimatePresence>
                                    <AnimatePresence>{SCALE && isProject && <motion.div exit={{opacity: 0}} id="layers" className='absolute bottom-0 left-20 opacity-0 w-8'><Players/></motion.div>}</AnimatePresence>
                                    <AnimatePresence>{SCALE && isProject && <motion.div exit={{opacity: 0}} id="maggi" className='absolute bottom-24 right-44 opacity-0 w-5'><Pmaggi/></motion.div>}</AnimatePresence>
                                    <AnimatePresence>{SCALE && isProject && <motion.div exit={{opacity: 0}} id="nescafe" className='absolute bottom-0 right-1/3 opacity-0 w-5'><Pnescafe/></motion.div>}</AnimatePresence>
                                    <AnimatePresence>{SCALE && isProject && <motion.div exit={{opacity: 0}} id="publicis" className='absolute bottom-20 left-1/3 opacity-0 w-5'><Ppublicis/></motion.div>}</AnimatePresence>
                                    <AnimatePresence>{SCALE && isProject && <motion.div exit={{opacity: 0}} id="rocket" className='absolute bottom-64 left-18 opacity-0 w-5'><Procket/></motion.div>}</AnimatePresence>
                                    <AnimatePresence>{SCALE && isProject && <motion.div exit={{opacity: 0}} id="sales" className='absolute top-[40%] right-1/3 opacity-0 w-5'><Psales/></motion.div> }</AnimatePresence>

                                    <AnimatePresence>{SCALE && isContact && <motion.div exit={{opacity: 0}} id="instagram" className='absolute top-[35%] right-16 opacity-0 w-8'><Cinstagram/></motion.div>}</AnimatePresence>
                                    <AnimatePresence>{SCALE && isContact && <motion.div exit={{opacity: 0}} id="gmail" className='absolute bottom-2 right-20 opacity-0 w-8'><Cgmail/></motion.div>}</AnimatePresence>
                                    <AnimatePresence>{SCALE && isContact && <motion.div exit={{opacity: 0}} id="telegram" className='absolute bottom-0 left-20 opacity-0 w-10'><Ctelegram/></motion.div>}</AnimatePresence>
                                    <AnimatePresence>{SCALE && isContact && <motion.div exit={{opacity: 0}} id="whatsapp" className='absolute bottom-60 left-16 opacity-0 w-10'><Cwhatsapp/></motion.div>}</AnimatePresence>
                                    <AnimatePresence>{SCALE && isContact && <motion.div exit={{opacity: 0}} id="linkedin" className='absolute top-[40%] left-1/3 opacity-0 w-5'><Clinkedin/></motion.div>}</AnimatePresence>
                                    <AnimatePresence>{SCALE && isContact && <motion.div exit={{opacity: 0}} id="internet" className='absolute bottom-20 left-1/2 opacity-0 w-5'><Cinternet/></motion.div>}</AnimatePresence>
                                    <AnimatePresence>{SCALE && isContact && <motion.div exit={{opacity: 0}} id="video" className='absolute bottom-40 left-[56%] opacity-0 w-5'><Cvideo/></motion.div>}</AnimatePresence>

                                    <AnimatePresence>{SCALE && isAbout && <motion.div exit={{opacity: 0}} id="germany" className='absolute bottom-[0%] right-20 opacity-0 w-8'><Agermany/></motion.div>}</AnimatePresence>
                                    <AnimatePresence>{SCALE && isAbout && <motion.div exit={{opacity: 0}} id="india" className='absolute bottom-0 right-1/2 opacity-0 w-8'><Aindia/></motion.div>}</AnimatePresence>
                                    <AnimatePresence>{SCALE && isAbout && <motion.div exit={{opacity: 0}} id="graphics" className='absolute bottom-0 left-20 opacity-0 w-8'><Agraphics/></motion.div>}</AnimatePresence>
                                    <AnimatePresence>{SCALE && isAbout && <motion.div exit={{opacity: 0}} id="ux" className='absolute bottom-60 right-20 opacity-0 w-5'><Aux/></motion.div>}</AnimatePresence>
                                    <AnimatePresence>{SCALE && isAbout && <motion.div exit={{opacity: 0}} id="information" className='absolute top-60 left-1/2 opacity-0 w-5'><Ainformation/></motion.div>}</AnimatePresence>
                                    <AnimatePresence>{SCALE && isAbout && <motion.div exit={{opacity: 0}} id="web" className='absolute bottom-64 left-18 opacity-0 w-5'><Aweb/></motion.div>}</AnimatePresence>
                                    
                                </div>
                                <div className="md:hidden absolute bottom-0 py-12"><ScrollBar sections={[1,2,3]}></ScrollBar></div>                                
                                
                        </div>
                       

                       
                </div>
                {isfirstTicker && <div className='absolute top-[93.5%] bg-hero text-backwhite font-thin w-full z-[100]'> <TickerComponent tickers={tickerHero}></TickerComponent></div>}
                {issecondtTicker && <div className='hidden md:block absolute -bottom-[0.5%] bg-hero text-backwhite font-thin w-full'> <TickerComponent tickers={tickerContent}></TickerComponent></div>}
                

                {/* {(showWork === 0)?<FooterComponent handleContactClick={handleContactClick}/>:""} */}
            {/* <motion.img className="h-32 fixed bottom-16 right-24 z-0" src={scrollImage} animate={{rotate:360, opacity:(showWork==0? 1: 0)}} transition={{ease:"linear", duration: 15, repeat:Infinity, opacity: { duration: 0.2}}}/> */}
            </div>
        </div>
        </>
    )
}