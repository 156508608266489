

export default function TickerComponent({tickers}: any) {

    return (
        <div className="relative flex overflow-x-hidden">
            <div className="py-3 animate-marquee whitespace-nowrap">
                <span className=" border-l-2 px-4">{tickers[0].content}</span>
                <span className=" border-l-2 px-4">{tickers[1].content}</span>
                <span className=" border-l-2 px-4">{tickers[0].content}</span>
                <span className=" border-l-2 px-4">{tickers[1].content}</span>
                <span className=" border-l-2 px-4">{tickers[0].content}</span>
                <span className=" border-l-2 px-4">{tickers[1].content}</span>
                <span className=" border-l-2 px-4">{tickers[0].content}</span>
                <span className=" border-l-2 px-4">{tickers[1].content}</span>
                

            </div>
      
            <div className="absolute top-0 py-3 animate-marquee2 whitespace-nowrap">
                <span className=" border-l-2 px-4">{tickers[1].content}</span>
                <span className=" border-l-2 px-4">{tickers[0].content}</span>
                <span className=" border-l-2 px-4">{tickers[1].content}</span>
                <span className=" border-l-2 px-4">{tickers[0].content}</span>
                <span className=" border-l-2 px-4">{tickers[1].content}</span>
                <span className=" border-l-2 px-4">{tickers[0].content}</span>
                <span className=" border-l-2 px-4">{tickers[1].content}</span>
                <span className=" border-l-2 px-4">{tickers[0].content}</span>

            </div>

        </div>
    )
}