import React, { useContext } from 'react'
import {motion,} from "framer-motion";
import { SectionContext } from '../../utils/SectionContext';

interface ScrollBarProps {
    sections: number[];
}

const ScrollBar: React.FC<ScrollBarProps> = ({sections}) => {

    const  sectionContext = useContext(SectionContext);
    
    const activeSection = sectionContext?.activeSection;
    const activeSectionProgress = sectionContext?.activeSectionProgress;

    return (
        <motion.div className='flex flex-row gap-3'>
            {sections.map((section : number) => (
            <motion.div 
            key = {section}
            layout
            transition={{duration: 0.2}}
            style = {{
                width: activeSection == section ? "4rem" : "1rem" ,
                borderRadius: 9999,
            }}
            className='h-4 overflow-hidden bg-hero'></motion.div>))}
        </motion.div>
    )
}

export default ScrollBar
