import React from 'react'

import { INSTAGRAM, LINKEDIN, MAIL } from '../../constants/links';
import  { useRef, useState, useEffect, CSSProperties, useContext } from 'react'


import { ReactComponent as Linkedin } from './../../assets/linkedin-round-svgrepo-com.svg'
import { ReactComponent as Instagram } from './../../assets/instagram-svgrepo-com.svg'
import { ReactComponent as Email } from './../../assets/email-svgrepo-com.svg'

import {
    motion
  } from "framer-motion";
import { useNavigate } from 'react-router-dom';
import FooterComponent from '../../components/footer';
import NavbarComponent from '../../components/navbar';



export default function AboutPage() {

    const[mouseVariant, setMouseVariant] = useState("default")
    const navigate = useNavigate()

    const[mousePosition, setMousePosition] = useState({
        x : 0,
        y : 0,
    })

    const variants = {
        default : {
            x : mousePosition.x -16,
            y : mousePosition.y -16,
            
        },

        text : {
            x : mousePosition.x - 16 ,
            y : mousePosition.y - 16,
            scale: 8,
        },

        floating : {
            x : mousePosition.x - 64,
            y : mousePosition.y - 32,
        }
    }

    useEffect(() => {
        const mouseMove = (e: MouseEvent) => {
            setMousePosition({
                x: e.clientX,
                y: e.clientY,
            })
        }
        window.addEventListener('mousemove', mouseMove)

        return () => {
            window.removeEventListener('mousemove', mouseMove)
        }

    }, []);

  return (
    <>
        <div className="bg-gradient-to-b from-black from-30% to-[#b3b3b3] bg-cover bg-center bg-fixed min-h-screen ">
            <motion.div variants={variants} className='hidden lg:block cursor fixed top-0 left-0 z-50' animate={mouseVariant} transition={{ type: "spring", duration: 0.0 }} >
            </motion.div>
            <div className='flex flex-col justify-between'>
            <NavbarComponent isVisible={true}></NavbarComponent>
                <div className='container mx-auto min-w-full md:min-w-0 md:max-w-screen-xl scrollbar'>
                <section className=" flex flex-col justify-center max-h-screen-2xl relative text-[0.8rem] mx-10">
                                <div className='absolute -top-20'></div>
                                <div className=' flex flex-col justify-between'>
                                    <div className="py-5 text-gray-200 flex justify-between">
                                        <div className='w-2/3 md:w-[38%] mb-7'> 
                                            <div className='py-6 font-bold'>ABOUT ME</div>
                                                <p className=''>
                                                Hello! I'm Shankh, a seasoned UX and product designer with a passion for crafting seamless and engaging digital experiences. With a diverse portfolio spanning the food, beverage, publishing, music and, tech industries, I bring a unique blend of creativity and strategic thinking to every project.
                                                </p>

                                                <p className='py-3'>
                                                Over the years, I have had the privilege of working with a wide range of clients, from innovative silicon valley unicorn startups like Branch Metrics to established brands like Nestle & Salesforce. This journey has not only honed my design skills but also broadened my perspective on how design can drive business success across different sectors and geographies.
                                                </p>

                                                <p className='py-3'>
                                                My approach to design is holistic and user-centric. I believe that great design starts with a deep understanding of the user’s needs and behaviours. By combining thorough research, intuitive design, and iterative testing, I aim to create products that are not only beautiful but also functional and impactful.
                                                </p>
                                                
                                                <p className='py-3 pb-10'>
                                                I am excited to connect with fellow creatives, potential collaborators, and anyone interested in the transformative power of design. Let's create something amazing together.
                                                </p>
                                                <button className='border p-3 w-40 rounded hover:bg-white hover:text-gray-900 transition-all cursor-none' onClick={(e)=>{
                                                        window.location.href = "mailto:" + MAIL;
                                                        e.preventDefault();
                                                    }}> Let's talk! </button>
                                        </div>  
                                            
                                        <div className='mt-10 w-1/5 fixed right-10 md:right-24 top-44 md:top-20 md:w-[4%]'>
                                            <div className='flex justify-center h-12 items-center' onClick={(e)=>{
                                                        window.location.href = "mailto:" + MAIL;
                                                        e.preventDefault();
                                                    }}><Email className='w-8 h-8 hover:w-9 hover:h-9 transition-all'/></div>
                                            <div className='flex justify-center h-12 items-center' onClick={(e)=>{
                                                        window.location.href = LINKEDIN
                                                    e.preventDefault()}}><Linkedin className='w-8 h-8 hover:h-9 hover:w-9 transition-all'/></div>
                                        </div>
                                    </div>
                                    
                                </div>
                </section>
                </div>
            <FooterComponent/>                                     
            </div>
        </div>
    
    </>
  )
}
