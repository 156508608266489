import { Link, useNavigate } from 'react-router-dom'
import {MAIL} from './../../constants/links'
import {useState} from 'react'


type haskLink = {
    isVisible: boolean

}

const NavbarComponent = ({ isVisible}: haskLink) => {
const navigate = useNavigate()
const [drop, setDrop] = useState(0);
const navBack = isVisible ? "h-[10vh] bg-[#000000] sticky top-0 z-40 text-backwhite" : "h-[10vh] bg-[#f5f5f5] sticky top-0 z-40"
const navStyle = isVisible ? "flex justify-between": "flex justify-center"

    return (
            <nav className={navBack}>
                <div className="container mx-auto max-w-screen-xl py-7 md:py-6 cursor-none">
                    <div className={navStyle}>

                       {isVisible ?  <div className="flex items-center mx-5">

                             <button
                                className=" hidden md:block md:w-28 cursor-none"
                                onClick={() => {
                                    
                                    navigate('/projects')
                                }}
                            >
                                <span className='font-light text-[20px]'>Projects</span>
                            </button>
                            <button
                                className=" hidden md:block md:w-28 cursor-none"
                                onClick={() => {
                                    
                                    navigate('/about')
                                }}
                            >
                                <span className='font-light text-[20px]'>About Me</span>
                            </button>

                        </div>:""}

                        <div
                            className=" flex items-center mx-5 cursor-none"
                            onClick={() => {

                                navigate('/');

                            }}
                        > 
                            <span className='font-bold text-[18px] md:text-[25px]'>SHANKH CHATTERJEE</span>
                        </div>

                        {isVisible ? <div className="flex items-center mx-5 relative">

                            <button
                                className=" block md:hidden w-8 cursor-none"
                                onClick={() => {
                                    let temp = (drop + 1) %2
                                    setDrop(temp)
                                }}
                            >
                                <span className=' text-gray-900 text-[20px]'> {(drop==0)?<svg viewBox="0 0 28 28" width="25" ><path d="M3 7C3 6.44771 3.44772 6 4 6H24C24.5523 6 25 6.44771 25 7C25 7.55229 24.5523 8 24 8H4C3.44772 8 3 7.55229 3 7Z" fill="#F5F5F5"/><path d="M3 14C3 13.4477 3.44772 13 4 13H24C24.5523 13 25 13.4477 25 14C25 14.5523 24.5523 15 24 15H4C3.44772 15 3 14.5523 3 14Z" fill="#F5F5F5"/><path d="M4 20C3.44772 20 3 20.4477 3 21C3 21.5523 3.44772 22 4 22H24C24.5523 22 25 21.5523 25 21C25 20.4477 24.5523 20 24 20H4Z" fill="#F5F5F5"/></svg>: <svg className='pl-2' fill="#F5F5F5" width="20" height="20" viewBox="0 0 1024 1024" ><g ><path d="M512.481 421.906L850.682 84.621c25.023-24.964 65.545-24.917 90.51.105s24.917 65.545-.105 90.51L603.03 512.377 940.94 850c25.003 24.984 25.017 65.507.033 90.51s-65.507 25.017-90.51.033L512.397 602.764 174.215 940.03c-25.023 24.964-65.545 24.917-90.51-.105s-24.917-65.545.105-90.51l338.038-337.122L84.14 174.872c-25.003-24.984-25.017-65.507-.033-90.51s65.507-25.017 90.51-.033L512.48 421.906z"></path></g></svg>}</span>
                            </button>
                                
                            {(drop == 0)?"": <div className='block md:hidden flex flex-col absolute top-16 z-50 right-1 w-16 text-[12px] font-light text-end'> 
                                    <div className='py-1' onClick={() => {navigate('/projects');}}> Projects </div>
                                    <div className='py-1 '  onClick={() => {navigate('/about');}}> About Me </div>
                                    <div className='py-1 '><a className='no-underline' href="Resume_Shankh_Chatterjee.pdf" target="blank" type="application/octet-stream">Resume</a> </div>
                                    <div className='py-1 '  onClick={() => {navigate('/contact');}}> Contact </div>
                                </div>}

                            <button
                                className=" hidden md:block w-14 md:w-28 cursor-none"
                                onClick={() => {
                                    
                                    //handleProjectClick();
                                }}
                            >
                                <span className='font-light text-[12px] md:text-[20px] '> <a className='no-underline' href="Resume_Shankh_Chatterjee.pdf" target="blank" type="application/octet-stream">Resume</a></span>
                            </button>

                            <button
                                className=" hidden md:block md:w-28 cursor-none"
                                onClick={() => {
                                    
                                    navigate('/contact');
                                }}
                            >
                                <span className='font-light text-[20px]'>Contact</span>
                            </button> 

                        </div> : ""}
                    </div>
                </div>
            </nav>
    )}

export default NavbarComponent;
