import React , { HTMLAttributes, useContext, useRef} from "react";
import { SectionContext } from "../../utils/SectionContext";
import { MotionValue, useMotionValueEvent, useScroll } from "framer-motion";

interface SectionProps extends HTMLAttributes<HTMLElement> {
    scrollval:MotionValue;
    section:number;
    isFirst?: boolean;
    isLast?: boolean;
}

const Section: React.FC<SectionProps> = ({
    scrollval,
    section,
    isFirst = false,
    isLast = false,
    children,
    ...props
})  => {
    const sectionContext = useContext(SectionContext);
    
    const setActiveSection = sectionContext?.setActiveSection ;
    const setActiveSectionProgress = sectionContext?.setActiveSectionProgress;

    useMotionValueEvent(scrollval, 'change', (value) => {
        if(value > 0 && value < 1){
            if(value < 0.34)    {
            setActiveSection?.(1);
            setActiveSectionProgress?.(value/0.34);
            }
            if(value > 0.34 && value < 0.67)    {
                setActiveSection?.(2);
                setActiveSectionProgress?.(value/0.34);
            }
            if(value > 0.67)    {
                setActiveSection?.(3);
                setActiveSectionProgress?.(value/0.34);
            }
            
        }

    })
    return (
        <div {...props}>{children}</div>
    )

}

export default Section