
import routes from '../constants/routes'
import { BrowserRouter, Routes, Route } from 'react-router-dom';

export default function Router() {


    // local non-states
    let _isAuth = false
    let _user = {pageName: ""}

    // Guard Functions

    // loading screen
    const LoadingScreen = () => {
        return (
            <p>Loading.....</p>
        )
    }

    

    return (
        <BrowserRouter>
                <Routes>
                {routes.map((route, index) => (
                        // Added condtions for route guarding  //  
                        <Route
                            path={route.path}
                            element={<route.component/>}
                        />
                    ))}
                </Routes>
                    
               
        </BrowserRouter>
    )
}
