import React from 'react';
import Router from "./router";
import { SectionContext, useSectionContextValues } from './utils/SectionContext';


function App() {
    const { values } = useSectionContextValues();
  return (  
    <SectionContext.Provider value={values}>
        <Router/>
    </SectionContext.Provider>
  );
}

export default App;
